import NavBar from "./NavBar";
import { HashRouter, Routes, Route } from "react-router-dom";
import Main from "./Main";
import Signup from "./Signup";
import VerifyAccount from "./VerifyAccount";
import UpdateEmail from "./UpdateEmail";
import NotFound from "./errorPages/NotFound";
import ResetPassword from "./ResetPassword";

import i18n from "./i18n";
function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/" exact element={<Main />} />
          <Route path="/signup" exact element={<Signup />} />
          <Route
            path="/verifyAccount/:link"
            exact
            element={<VerifyAccount />}
          />
          <Route path="/updateEmail/:link" exact element={<UpdateEmail />} />
          <Route
            path="/resetPassword/:link"
            exact
            element={<ResetPassword />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
