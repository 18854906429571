import React from "react";

import wf from "./Assets/logo.png";
const Loader = () => {
  return (
    <>
      <div className="loader" style={{ margin: "auto" }}>
        <center>
          <img
            src={wf}
            alt="logo"
            style={{
              width: "100%",
              maxWidth: "500px",
              height: "100%",

              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </center>

        <lottie-player
          src="https://assets7.lottiefiles.com/packages/lf20_fyye8szy.json"
          background="transparent"
          speed="1"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "80px",
            height: "50px",
          }}
          loop
          autoplay
        ></lottie-player>
      </div>
    </>
  );
};

export default Loader;
