import React from 'react'

const Spacer = (props) => {
  
            if(props.size){
                return <div style={{height:`${props.size}px`}}></div>
            }else {
                return  <div style={{height:'20px'}}></div>
            }
    
}

export default Spacer