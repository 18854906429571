import React, { useEffect, useState, useRef } from "react";
import { Button, Container } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import sphere from "./Assets/sphere.png";

import { GiHamburgerMenu } from "react-icons/gi";

import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

import { BsGlobe } from "react-icons/bs"; // Import the globe icon from React Bootstrap Icons

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [cookie, setCookie, removeCookie] = useCookies("token");
  const logout = () => {
    removeCookie("token", { path: "/" });
    window.location.reload();
  };

  useEffect(() => {
    const savedLanguage = cookie.language || "en";
    i18n.changeLanguage(savedLanguage);
  }, [cookie.language, i18n]);

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setCookie("language", language, { path: "/" });
    setSelectedLanguage(language);
  };

  const [refresh, setRefresh] = useState(null);
  const navDrawerStatus = useRef({
    show: false,
  });

  const reload = () => {
    setRefresh(Math.random());
  };

  const controlDrawer = () => {
    if (navDrawerStatus.current.show === true) {
      navDrawerStatus.current.show = false;
      reload();
    } else {
      navDrawerStatus.current.show = true;
      reload();
    }
  };

  return (
    <>
      <nav className="navBar">
        <Container fluid>
          <div style={{ display: "inline-flex", gap: "10px" }}>
            <p style={{ margin: "-5px 15px" }}>
              <img
                src={sphere}
                alt="sphere"
                style={{ height: "45px" }}
                onClick={() => window.location.replace(`#`)}
              />{" "}
            </p>
          </div>
          <Menu
            menuButton={
              <Button
                style={{
                  margin: "3px 4px",
                  float: "right",
                  height: "30px",
                  padding: "4px",
                  width: "30px",
                  borderRadius: "45px",
                  backgroundColor: "#E74A3B",
                  borderColor: "#E74A3B",
                  textAlign: "center",
                }}
              >
                {" "}
                <BsFillPersonFill />{" "}
              </Button>
            }
            transition
          >
            <MenuItem onClick={() => logout()}>{t("LOGOUT")}</MenuItem>
          </Menu>
          <Menu
            align="end"
            position="top"
            menuButton={
              <Button
                style={{
                  margin: "3px 4px",
                  float: "right",
                  height: "30px",
                  padding: "4px",
                  width: "30px",
                  borderRadius: "45px",
                  backgroundColor: "#E74A3B",
                  borderColor: "#E74A3B",
                  textAlign: "center",
                }}
              >
                {" "}
                <BsGlobe />
              </Button>
            }
            transition
            arrow
          >
            <MenuItem onClick={() => changeLanguage("en")}>🇺🇸 English</MenuItem>
            <MenuItem onClick={() => changeLanguage("fr")}>🇫🇷 French</MenuItem>
          </Menu>
        </Container>
      </nav>
    </>
  );
};

export default NavBar;
