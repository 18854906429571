import React, { useEffect, useState, useRef } from "react";
import NavBar from "./NavBarO";
import Spacer from "./Spacer";
import { Button, Input, Link } from "semantic-ui-react";
import access from "./Assets/access.png";
import { AiOutlineEyeInvisible, AiFillEye } from "react-icons/ai";
import play from "./Assets/play.jpg";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Central from "./Central";
import { Form } from "react-bootstrap";
import { Message } from "semantic-ui-react";

import Video from "./Video";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [isLoadingBtn, setLoadingBtn] = useState(false);

  const [cookies, setCookie] = useCookies(["token"]);

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  const modals = (title, body) => {
    setModalTitle(title);
    setModalBody(body);
    setShow(true);
  };

  const login = () => {
    setLoadingBtn(true);

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (!email) {
      setModalBody(t("ENTER_EMAIL_ID"));
      setShow(true);
      setLoadingBtn(false);
      return;
    }
    if (!password) {
      setModalBody(t("ENTER_PASSWORD"));
      setShow(true);
      setLoadingBtn(false);
      return;
    }

    // setCookie('token', "wofdfdfw", { path: '/' });

    const formData = new FormData();
    formData.append("action", "getLoginToken");
    formData.append("email", email);
    formData.append("password", password);

    axios({
      method: "post", //you can set what request you want to be
      url: Central.accountsUrl,
      data: formData,
      headers: {
        // Authorization: 'Bearer ' + 'M2M1ZDk0NmEwNDM3NzQwODI1MDVhZmI5NGRhZTU4ZTI'
      },
    })
      .then((res) => {
        return res.data;
        //   document.cookie = 'token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2NDcyNTE0MTksImlzcyI6ImFkbWluLndpZGVmbGFyZS5jb20iLCJuYmYiOjE2NDcyNTE0MTksImV4cCI6MTY0OTg0MzQxOSwidXNlcl9pZCI6Mjd9.7NYOprHJtZQziqZAeJabqoUXaQ_VtisWlLgqpCCSAaKrZ4umij7-haJ4RhcjEXvTGYpdpZfYA-sx8B1EcKQybw; Path=/; Expires=Wed, 13 Apr 2022 09:50:19 GMT;'
      })
      .then((data) => {
        setLoadingBtn(false);

        console.log(data);
        switch (data.response.code) {
          case 200:
            if (data.response.status === "success") {
              const token = data.token;

              const fd = new FormData();
              fd.append("token", token);
              fd.append("action", "auth");

              axios({
                method: "post", //you can set what request you want to be
                url: Central.accountsUrl,
                data: fd,
                headers: {
                  // Authorization: 'Bearer ' + 'M2M1ZDk0NmEwNDM3NzQwODI1MDVhZmI5NGRhZTU4ZTI'
                },
              })
                .then((e) => {
                  return e.data;
                })
                .then((data) => {
                  console.log(data);

                  switch (data.response.code) {
                    case 200:
                      const expir = parseJwt(data.token);

                      const d = new Date(expir.exp * 1000);
                      setCookie("token", data.token, { path: "/", expires: d });
                      window.location.reload();
                      break;

                    case 400:
                      modals(t("FAILED"), t("TRY_AGAIN_LATER"));
                      break;

                    case 500:
                      modals(t("FAILED"), t("TRY_AGAIN_LATER"));
                      break;
                    case 401:
                      modals(t("FAILED"), t("TRY_AGAIN_LATER"));
                      break;
                  }
                })
                .catch((err) => console.log(err));
            }

            break;

          case 400:
            if (data.response.status === "invalid-email") {
              setModalTitle(t("FAILED"));
              setModalBody(t("INVALID_EMAIL_ID"));
              setShow(true);
              return;
            }
            if (data.response.status === "email-not-set") {
              setModalTitle(t("FAILED"));
              setModalBody(t("ENTER_EMAIL_ID"));
              setShow(true);
              return;
            }
            if (data.response.status === "password-not-set") {
              setModalTitle(t("FAILED"));
              setModalBody(t("ENTER_PASSWORD"));
              setShow(true);
              return;
            }

            if (data.response.status === "invalid-credentials") {
              setModalTitle(t("FAILED"));
              setModalBody(t("INVALID_EMAIL_OR_PASSWORD"));
              setShow(true);
              return;
            }

            break;

          case "500":
            setModalTitle(t("SERVER_ERROR"));
            setModalBody(t("TRY_AGAIN_LATER"));
            setShow(true);
            return;

            break;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingBtn(false);
      });
  };

  const resetPass = () => {
    var email = forgotPassEmailInputEmailRef.current.value;
    setForgotPassErrorMsg(null);
    setResetPassSuccessMsg(null);
    if (!email) {
      setForgotPassErrorMsg(t("ENTER_EMAIL_ID_ASSOCIATED_WITH_PLENTRA"));
      return;
    }

    setResetPassLoadingBtn(true);

    const fd = new FormData();
    fd.append("action", "getResetToken");
    fd.append("email", email);

    axios({
      url: Central.accountsUrl,
      method: "post",
      data: fd,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);

        switch (data.response.code) {
          case 500:
            setResetPassLoadingBtn(false);
            setForgotPassErrorMsg(t("SERVER_ERROR_TRY_LATER"));
            break;

          case 400:
            setResetPassLoadingBtn(false);
            switch (data.response.status) {
              case "email-not-set":
                setForgotPassErrorMsg(t("ENTER_EMAIL_ID"));
                break;
              case "invalid-email":
                setForgotPassErrorMsg(t("INVALID_EMAIL_ID"));
                break;
              case "account-does-not-exist":
                setForgotPassErrorMsg(t("ACCOUNT_WITH_THIS_EMAIL_NOT_EXIST"));
                break;
              case "account-does-not-exist":
                setForgotPassErrorMsg(t("ACCOUNT_WITH_THIS_EMAIL_NOT_EXIST"));
                break;
            }
            break;

          case 200:
            //success
            setResetPassLoadingBtn(false);
            if (data.response.status === "please-wait") {
              const hours = Math.floor(data.duration / 3600);
              const minutes = Math.floor((data.duration % 3600) / 60);
              setForgotPassErrorMsg(
                t("SENT_ENOUGH_VERIFICATIONS") +
                  " " +
                  hours +
                  t("HOURS") +
                  " " +
                  minutes +
                  +" " +
                  t("MINUTES_AND_TRY_AGAIN")
              );
              return;
            }

            setResetPassSuccessMsg(t("RESET_PASS_MAIL_SENT"));

            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [resetPassLoadingBtn, setResetPassLoadingBtn] = useState(false);
  const [resetPassSuccessMsg, setResetPassSuccessMsg] = useState(null);

  const [showForgotPassModal, setShowForgotPassModal] = useState(false);
  const forgotPassEmailInputEmailRef = useRef(null);
  const [forgotPassErrorMsg, setForgotPassErrorMsg] = useState(null);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {t("CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showForgotPassModal}
        onHide={() => setShowForgotPassModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("RESET_PASSWORD")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {t("EMAIL_ID_ASSOCIATED_WITH_PLENTRA")}
            <Form.Control
              ref={forgotPassEmailInputEmailRef}
              placeholder={t("ENTER_EMAIL_ID")}
            />
          </div>
          {forgotPassErrorMsg === null ? (
            <div></div>
          ) : (
            <Message error>
              <Message.Header>{t("ERROR")}</Message.Header>
              <p>{forgotPassErrorMsg}</p>
            </Message>
          )}

          {resetPassSuccessMsg === null ? (
            <div></div>
          ) : (
            <Message positive>
              <Message.Header>{t("VERIFICATION")}</Message.Header>
              <p>{resetPassSuccessMsg}</p>
            </Message>
          )}
        </Modal.Body>
        <Modal.Footer>
          {resetPassLoadingBtn === true ? (
            <Button positive loading>
              {t("RESET")}
            </Button>
          ) : (
            <Button
              positive
              onClick={() => {
                resetPass();
              }}
            >
              {t("RESET")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <div>
        <NavBar />

        <Video video="https://assets.plentratechnologies.com/atoms-bg.mp4" />

        <div className="body" style={{ position: "absolute", width: "100%" }}>
          <Spacer size={75} />

          <div className="content">
            <div className="custom-card">
              <div style={{ textAlign: "center" }}>
                <h2 style={{ padding: "10px" }}>
                  {t("WELCOME_TO_PLENTRA_ACCOUNTS")}
                </h2>

                {/* <img src={access} alt="access" style={{width:'250px'  , borderRadius : '5px'}} /> */}

                <center>
                  <lottie-player
                    src="https://assets7.lottiefiles.com/private_files/lf30_iraugwwv.json"
                    background="transparent"
                    speed="1"
                    style={{ width: "250px", borderRadius: "5px" }}
                    loop
                    autoplay
                  ></lottie-player>
                </center>

                <Spacer />
                <center>
                  <Form.Control
                    placeholder={t("ENTER_EMAIL_ID")}
                    ref={emailRef}
                    style={{ width: "100%", maxWidth: "500px" }}
                  />
                </center>
                <Spacer size={5} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Form.Control
                    placeholder={t("ENTER_PASSWORD")}
                    ref={passwordRef}
                    type={passwordType}
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                  <Button
                    style={{ margin: "0px 5px" }}
                    onClick={(e) => {
                      if (passwordType === "password") {
                        setPasswordType("text");
                      } else {
                        setPasswordType("password");
                      }
                    }}
                  >
                    {passwordType !== "password" ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </Button>
                </div>
                <Spacer size={2} />
                <a
                  onClick={() => {
                    setForgotPassErrorMsg(null);
                    setShowForgotPassModal(true);
                  }}
                  style={{
                    color: "#0d6efd",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {t("FORGOT_PASSWORD")}
                </a>

                <Spacer size={10} />
                {isLoadingBtn === true ? (
                  <Button positive loading>
                    {t("SIGNIN")}
                  </Button>
                ) : (
                  <Button positive onClick={() => login()}>
                    {t("SIGNIN")}
                  </Button>
                )}

                <Button
                  secondary
                  onClick={() => (window.location.href = "#/signup")}
                >
                  {t("CREATE_NEW_ACCOUNT")}
                </Button>

                <Spacer size={20} />
                <Message visible>
                  <center>{t("COLLABORATION")}</center>
                </Message>
                <Spacer size={5} />

                <center>{t("COPYRIGHT")}</center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
