import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NavBar from "./NavBarO";
import Spacer from "./Spacer";
import clive from "./Assets/clive.jpg";
import axios from "axios";
import Static from "./Static";

import { useTranslation } from "react-i18next";

const LinkExpired = () => {
  const { t } = useTranslation();
  return (
    <div className="content">
      <div className="custom-card">
        <div style={{ textAlign: "center" }}>
          <lottie-player
            src="https://assets1.lottiefiles.com/packages/lf20_GwRqA3.json"
            background="transparent"
            speed="1"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "100px",
              height: "100px",
            }}
            loop
            autoplay
          ></lottie-player>

          <h2 style={{ padding: "10px" }}>{t("LINK_EXPIRED")}</h2>
          <p>{t("LINK_EXPIRED_MSG")}</p>
        </div>
      </div>
    </div>
  );
};

const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className="content">
      <div className="custom-card">
        <div style={{ textAlign: "center" }}>
          <lottie-player
            src="https://assets1.lottiefiles.com/packages/lf20_p8bfn5to.json"
            background="transparent"
            speed="1"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "100px",
              height: "100px",
            }}
            loop
            autoplay
          ></lottie-player>

          <h2 style={{ padding: "10px" }}>{t("LOADING")}</h2>
          <p>{t("PLEASE_WAIT")}</p>
        </div>
      </div>
    </div>
  );
};

const AccountCreated = () => {
  const { t } = useTranslation();

  return (
    <div className="content">
      <div className="custom-card">
        <div style={{ textAlign: "center" }}>
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_y2hxPc.json"
            background="transparent"
            speed="1"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "100px",
              height: "100px",
            }}
            loop
            autoplay
          ></lottie-player>

          <h2 style={{ padding: "10px" }}>{t("EMAIL_ID_UPDATED")}</h2>
          <p>{t("THANK_YOU_FOR_CONFIRMING")}</p>
        </div>
      </div>
    </div>
  );
};
const ServerError = () => {
  const { t } = useTranslation();

  return (
    <div className="content">
      <div className="custom-card">
        <div style={{ textAlign: "center" }}>
          <lottie-player
            src="https://assets2.lottiefiles.com/packages/lf20_tl52xzvn.json"
            background="transparent"
            speed="1"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "100px",
              height: "100px",
            }}
            loop
            autoplay
          ></lottie-player>

          <h2 style={{ padding: "10px" }}>{t("INTERNAL_SERVER_ERROR")}</h2>
          <p>{t("TRY_AGAIN_LATER")}</p>
        </div>
      </div>
    </div>
  );
};

const UpdateEmail = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);

  const { link } = useParams();

  const formData = new FormData();
  formData.append("action", "verifyEmailChange");
  formData.append("token", link);

  useEffect(() => {
    axios({
      method: "POST",
      url: Static.ACCOUNTS_URL,
      data: formData,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);

        switch (data.response.status) {
          case "internal-server-error":
            setPage(3);
            break;
          case "success":
            setPage(2);
            break;
          case "invalid-token":
            setPage(1);
            break;
        }
      });
  }, []);

  if (page === 0) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />
            <Loading />
          </div>
        </div>
      </>
    );
  } else if (page === 1) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />

            <LinkExpired />
          </div>
        </div>
      </>
    );
  } else if (page === 2) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />
            <AccountCreated />
          </div>
        </div>
      </>
    );
  } else if (page === 3) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />
            <ServerError />
          </div>
        </div>
      </>
    );
  }
};

export default UpdateEmail;
