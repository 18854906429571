import React, { useEffect, useState, useRef } from "react";
import { Button, Container } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import sphere from "./Assets/sphere.png";

import { GiHamburgerMenu } from "react-icons/gi";

import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

import { BsGlobe } from "react-icons/bs";

const NavBarO = () => {
  const { t, i18n } = useTranslation();
  const [cookie, setCookie] = useCookies("language");

  useEffect(() => {
    const savedLanguage = cookie.language || "en";
    i18n.changeLanguage(savedLanguage);
  }, [cookie.language, i18n]);

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setCookie("language", language, { path: "/" });
    setSelectedLanguage(language);
  };

  return (
    <>
      <nav className="navBar">
        <Container fluid>
          <div style={{ display: "inline-flex", gap: "10px" }}>
            <div style={{ margin: "3px 0px" }}>{/* Use the globe icon */}</div>
            <p style={{ margin: "-5px 0px" }}>
              <img src={sphere} alt="sphere" style={{ height: "45px" }} />
            </p>
          </div>
          <div style={{ float: "right", margin: "3px 0px" }}>
            <Menu
              align="end"
              position="top"
              menuButton={
                <Button
                  style={{
                    height: "30px",
                    padding: "4px",
                    width: "30px",
                    borderRadius: "45px",
                    backgroundColor: "#E74A3B",
                    borderColor: "#E74A3B",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  <BsGlobe />{" "}
                </Button>
              }
              transition
              arrow
            >
              <MenuItem onClick={() => changeLanguage("en")}>
                🇺🇸 English
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("fr")}>
                🇫🇷 French
              </MenuItem>
            </Menu>
          </div>
        </Container>
      </nav>
    </>
  );
};

export default NavBarO;
