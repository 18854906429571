import React from 'react'

const Video = (props) => {
  return <>
     <video style={{ minWidth:'100%' , minHeight:'100%' , position:'fixed'  , right:'0' , bottom:'0' }} className='videoTag' autoPlay loop muted>
    <source src={props.video} type="video/mp4"/>
</video>
  </>
}

export default Video