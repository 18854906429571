import { React, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import NavBar from "./NavBarO";
import Spacer from "./Spacer";
import clive from "./Assets/clive.jpg";
import axios from "axios";
import { Form, Modal } from "react-bootstrap";
import Static from "./Static";
import { Button } from "semantic-ui-react";

import { AiOutlineEyeInvisible, AiFillEye } from "react-icons/ai";

import { useTranslation } from "react-i18next";

const LinkExpired = () => {
  const { t } = useTranslation();
  return (
    <div className="content">
      <div className="custom-card">
        <div style={{ textAlign: "center" }}>
          <lottie-player
            src="https://assets1.lottiefiles.com/packages/lf20_GwRqA3.json"
            background="transparent"
            speed="1"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "100px",
              height: "100px",
            }}
            loop
            autoplay
          ></lottie-player>

          <h2 style={{ padding: "10px" }}>{t("LINK_EXPIRED")}</h2>
          <p>{t("LINK_EXPIRED_MSG")}</p>
        </div>
      </div>
    </div>
  );
};

const Success = () => {
  const { t } = useTranslation();
  return (
    <div className="content">
      <div className="custom-card">
        <div style={{ textAlign: "center" }}>
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_y2hxPc.json"
            background="transparent"
            speed="1"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "100px",
              height: "100px",
            }}
            loop
            autoplay
          ></lottie-player>

          <h2 style={{ padding: "10px" }}>
            {t("YOUR_ACCOUNT_WAS_SUCCESSFULLY_UPDATED")}
          </h2>
          <p></p>
        </div>
      </div>
    </div>
  );
};

const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className="content">
      <div className="custom-card">
        <div style={{ textAlign: "center" }}>
          i
          <lottie-player
            src="https://assets1.lottiefiles.com/packages/lf20_p8bfn5to.json"
            background="transparent"
            speed="1"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "100px",
              height: "100px",
            }}
            loop
            autoplay
          ></lottie-player>
          <h2 style={{ padding: "10px" }}>{t("LOADING")}</h2>
          <p>{t("PLEASE_WAIT")}</p>
        </div>
      </div>
    </div>
  );
};

const ServerError = () => {
  const { t } = useTranslation();
  return (
    <div className="content">
      <div className="custom-card">
        <div style={{ textAlign: "center" }}>
          <lottie-player
            src="https://assets2.lottiefiles.com/packages/lf20_tl52xzvn.json"
            background="transparent"
            speed="1"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "100px",
              height: "100px",
            }}
            loop
            autoplay
          ></lottie-player>

          <h2 style={{ padding: "10px" }}>{t("INTERNAL_SERVER_ERROR")}</h2>
          <p>{t("TRY_AGAIN_LATER")}</p>
        </div>
      </div>
    </div>
  );
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const { link } = useParams();

  const ResetForm = () => {
    const newPasswordInputRef = useRef(null);
    const reNewPasswordInputRef = useRef(null);

    const { link } = useParams();

    const [resetLoadingBtn, setResetLoadingBtn] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const throwError = (message) => {
      setErrorMessage(message);
      setShowErrorModal(true);
    };

    const updatePassword = () => {
      var new_password = newPasswordInputRef.current.value.trim();
      var re_new_password = reNewPasswordInputRef.current.value.trim();

      if (!new_password) {
        throwError(t("ENTER_NEW_PASSWORD"));
        return;
      }
      if (!re_new_password) {
        throwError(t("RE_ENTER_NEW_PASSWORD"));
        return;
      }

      if (new_password !== re_new_password) {
        throwError(t("PASSWORDS_NOT_MATCH"));
        return;
      }

      setResetLoadingBtn(true);
      const fd = new FormData();
      fd.append("action", "resetAccountPassword");
      fd.append("token", link);
      fd.append("password", new_password);

      axios({
        url: Static.ACCOUNTS_URL,
        method: "post",
        data: fd,
      })
        .then((resp) => resp.data)
        .then((data) => {
          console.log(data);
          setResetLoadingBtn(true);

          switch (data.response.code) {
            case 500:
              setPage(3);
              break;

            case 400:
              switch (data.response.status) {
                case "password-max-length-reached":
                  throwError(t("PASSWORD_EXCEEDED"));
                  break;

                case "token-expired-or-invalid-token":
                  setPage(1);
                  break;

                case "account-not-exist":
                  throwError(t("ACCOUNT_NOT_EXIST"));
                  break;
              }
              break;
            case 200:
              setPage(4);
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const [passwordType, setPasswordType] = useState("password");
    const [rePasswordType, setRePasswordType] = useState("password");

    return (
      <>
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("ERROR")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{errorMessage}</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowErrorModal(false)}>
              {t("CLOSE")}
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="content">
          <div className="custom-card">
            <div style={{ textAlign: "center" }}>
              <lottie-player
                src="https://assets2.lottiefiles.com/packages/lf20_owiuq8fd.json"
                background="transparent"
                speed="1"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "100px",
                  height: "100px",
                }}
                loop
                autoplay
              ></lottie-player>

              <center>
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                    maxWidth: "400px",
                    margin: "0px auto",
                  }}
                >
                  {t("NEW_PASSWORD")}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Form.Control
                      placeholder={t("ENTER_HERE")}
                      ref={newPasswordInputRef}
                      type={passwordType}
                      style={{ width: "100%", maxWidth: "400px" }}
                    />
                    <Button
                      style={{ margin: "0px 5px" }}
                      onClick={(e) => {
                        if (passwordType === "password") {
                          setPasswordType("text");
                        } else {
                          setPasswordType("password");
                        }
                      }}
                    >
                      {passwordType !== "password" ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </Button>
                  </div>{" "}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                    maxWidth: "400px",
                    margin: "0px auto",
                  }}
                >
                  {t("RE_ENTER_PASSWORD")}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Form.Control
                      placeholder={t("ENTER_HERE")}
                      ref={reNewPasswordInputRef}
                      type={rePasswordType}
                      style={{ width: "100%", maxWidth: "400px" }}
                    />
                    <Button
                      style={{ margin: "0px 5px" }}
                      onClick={(e) => {
                        if (rePasswordType === "password") {
                          setRePasswordType("text");
                        } else {
                          setRePasswordType("password");
                        }
                      }}
                    >
                      {rePasswordType !== "password" ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </Button>
                  </div>{" "}
                </div>
                <Spacer />
              </center>

              {resetLoadingBtn ? (
                <Button loading positive>
                  {t("RESET")}
                </Button>
              ) : (
                <Button positive onClick={() => updatePassword()}>
                  {t("UPDATE")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const formData = new FormData();
  formData.append("action", "validateResetPasswordLink");
  formData.append("token", link);

  useEffect(() => {
    axios({
      method: "POST",
      url: Static.ACCOUNTS_URL,
      data: formData,
    })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);

        switch (data.response.status) {
          case "internal-server-error":
            setPage(3);
            break;
          case "success":
            setPage(2);
            break;
          case "token-expired-or-invalid-token":
            setPage(1);
            break;
        }
      });
  }, []);

  if (page === 0) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />
            <Loading />
          </div>
        </div>
      </>
    );
  } else if (page === 1) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />

            <LinkExpired />
          </div>
        </div>
      </>
    );
  } else if (page === 2) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />
            <ResetForm setPage Central />
          </div>
        </div>
      </>
    );
  } else if (page === 3) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />
            <ServerError />
          </div>
        </div>
      </>
    );
  } else if (page === 4) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />
            <Success />
          </div>
        </div>
      </>
    );
  }
};

export default ResetPassword;
