import React from "react";

import { Button } from "semantic-ui-react";
import NavBarO from "../NavBarO";
import badRequest from "../Assets/badRequest.png";
import Spacer from "../Spacer";

import { useTranslation } from "react-i18next";

const BadRequest = () => {
  const { t } = useTranslation();
  return (
    <>
      <NavBarO />
      <div className="loader" style={{ margin: "auto", textAlign: "center" }}>
        <img
          src={badRequest}
          alt="error-img"
          style={{ width: "100%", maxWidth: "400px", height: "100%" }}
        ></img>

        <Spacer size={5} />

        <h4 style={{ textAlign: "center", marginBottom: "0px" }}>
          {t("BAD_REQUEST")}
        </h4>
        <Spacer size={5} />
        <h5
          style={{
            textAlign: "center",
            marginTop: "6px",
            marginBottom: "0px",
            color: "#606c76",
          }}
        >
          {t("BAD_REQUEST_MSG")}
        </h5>
        <Spacer size={5} />

        <Button primary onClick={() => window.location.reload()}>
          {t("REFRESH")}
        </Button>
      </div>
    </>
  );
};

export default BadRequest;
