import React from "react";
import { Button } from "semantic-ui-react";
import NavBarO from "../NavBarO";
import lost from "../Assets/lost.png";
import Spacer from "../Spacer";

import { useTranslation } from "react-i18next";

const Unauthorized = () => {
  const { t } = useTranslation();
  return (
    <>
      <NavBarO />
      <div className="loader" style={{ margin: "auto", textAlign: "center" }}>
        <img
          src={lost}
          alt="error-img"
          style={{ width: "100%", maxWidth: "400px", height: "100%" }}
        ></img>

        <Spacer size={5} />

        <h4 style={{ textAlign: "center", marginBottom: "0px" }}>
          {t("UNAUTHORIZED")}
        </h4>
        <Spacer size={5} />
        <h5
          style={{
            textAlign: "center",
            marginTop: "6px",
            marginBottom: "0px",
            color: "#606c76",
          }}
        >
          {t("UNAUTHORIZED_MSG")}
        </h5>
        <Spacer size={5} />
        <p>{t("REFRESH_TO_LOGIN")}</p>
        <Button primary onClick={() => window.location.reload()}>
          {t("REFRESH")}
        </Button>
      </div>
    </>
  );
};

export default Unauthorized;
